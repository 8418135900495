@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .icon-eye {
    right: 16px;
    bottom: 13px;
    cursor: pointer;
  }

  @media (max-width: 767px) {
    .icon-eye {
      bottom: 16px;
    }
  }

  .icon-eye:after {
    content: "";
    display: block;
    background: url(/src/assets/img/icon-eye.svg) 50% / contain no-repeat;
    width: 20px;
    height: 14px;
  }

  @media (max-width: 767px) {
    .icon-eye:after {
      width: 14px;
      height: 10px;
    }
  }

  .icon-eye:before {
    content: "";
    display: none;
    width: 4px;
    height: 20px;
    background: #fff;
    border-left: 2px solid #d4d4d4;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  @media (max-width: 767px) {
    .icon-eye:before {
      border-left: 1px solid #d4d4d4;
      width: 2px;
      height: 15px;
    }
  }

  .icon-eye.is-show:before {
    display: block;
  }

  .login-form {
    width: 700px;
    height: 700px;
    background: url(/src/assets/img/hexagon.svg) 50% / contain no-repeat;
  }

  @media (max-width: 767px) {
    .login-form {
      width: 100%;
      height: auto;
      background: #fff;
      -webkit-box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    }
  }

  .login-btn {
    background: -o-linear-gradient(top, #52b2ba, #3c9da5);
    background: linear-gradient(180deg, #52b2ba, #3c9da5);
  }

  .bg-d4 {
    --tw-bg-opacity: 1;
    background-color: rgba(212, 212, 212, var(--tw-bg-opacity));
  }

  .bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }

  .bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .bg-blue {
    background-color: rgba(82, 178, 186, var(--tw-text-opacity));
  }

  .bg-l-blue {
    --tw-bg-opacity: 1;
    background-color: rgba(82, 178, 186, var(--tw-bg-opacity));
  }

  .bg-e5 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 229, 229, var(--tw-bg-opacity));
  }

  .text-blue {
    --tw-text-opacity: 1;
    color: rgba(82, 178, 186, var(--tw-text-opacity));
  }

  .border-blue {
    --tw-border-opacity: 1;
    border-color: rgba(82, 178, 186, var(--tw-border-opacity));
  }

  .border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }

  .border-l-6 {
    border-left-width: 6px;
  }

  .border-b {
    border-bottom-width: 1px;
  }

  .border-bc {
    --tw-border-opacity: 1;
    border-color: rgba(188, 188, 188, var(--tw-border-opacity));
  }

  .border-b-6 {
    border-bottom-width: 6px;
  }

  .border-c {
    --tw-border-opacity: 1;
    border-color: rgba(204, 204, 204, var(--tw-border-opacity));
  }

  .border-d4 {
    --tw-border-opacity: 1;
    border-color: rgba(212, 212, 212, var(--tw-border-opacity));
  }

  .border-t {
    border-top-width: 1px;
  }

  .button {
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#fff),
      to(#f2f2f2)
    );
    background: -o-linear-gradient(top, #fff, #f2f2f2);
    background: linear-gradient(180deg, #fff, #f2f2f2);
    -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    -webkit-border-radius: 3px;
    border-radius: 3px;
  }

  .teacher {
    padding-top: 114px;
  }

  .min-h-screen {
    min-height: 100vh;
  }

  .min-h-screen {
    min-height: -webkit-calc(var(--vh, 1vh) * 100);
    min-height: calc(var(--vh, 1vh) * 100);
  }

  .mr-a,
  .mx-a {
    margin-right: auto;
  }

  .max-w-sm {
    max-width: 24rem;
  }

  .loading-color {
    --tw-border-opacity: 1;
    border-color: rgba(82, 178, 186, var(--tw-border-opacity));
    border-top-color: transparent;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
